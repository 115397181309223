import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ReactQuill, { Quill } from 'react-quill';
import ImageResize from 'quill-image-resize-module-react';
import 'react-quill/dist/quill.snow.css';
import {
    MenuItem,
    Select,
    FormControl,
    Autocomplete,
    TextField,
    Box,
    useTheme,
    Button,
    Grid,
    IconButton,
    Tooltip
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CheckIcon from "@mui/icons-material/Check";
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import Cookies from "js-cookie";
import axios from 'axios';

import { tokens } from "../../theme";
import Header from "../../components/Header";

const EditProduct = () => {
    const { productid } = useParams();

    const isNonMobile = useMediaQuery("(min-width:600px)");

    const [product, setProduct] = useState(null);

    const [showAddImageTooltip, setShowAddImageTooltip] = useState(false);

    const [selectedCategories, setSelectedCategories] = useState([]);
    const [category, setCategory] = useState([])

    const [updatedProduct, setUpdatedProduct] = useState({});

    const [additionalInformation, setAdditionalInformation] = useState('');
    const [description, setDescription] = useState('');

    const [imageUrls, setImageUrls] = useState([]);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const [currentImageUrl, setCurrentImageUrl] = useState('');

    const [isEdited, setIsEdited] = useState(false);


    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const gBASE_URL = process.env.REACT_APP_API_URL;

    const accessToken = Cookies.get('accessToken');

    Quill.register('modules/imageResize', ImageResize);

    // load category
    const fetchCategory = async () => {
        try {
            const response = await fetch(`${gBASE_URL}/category`);
            const data = await response.json();
            if (response.ok) {
                setCategory(data.data);
            } else {
                // xử lý lỗi
            }
        } catch (error) {
            // xử lý lỗi
        }
    }
    useEffect(() => {
        fetchCategory()
    }, [])

    const fetchProduct = async () => {
        try {
            const response = await fetch(`${gBASE_URL}/product/${productid}`);
            const data = await response.json();
            setProduct(data.data);
            setImageUrls(data.data.imageUrls);
        } catch (error) {
            console.error("Error fetching product:", error);
        }
    };

    useEffect(() => {
        fetchProduct();
    }, [productid]);


    useEffect(() => {
        if (product && product.category) {
            setSelectedCategories(product.category.map(cat => cat.name));
        }
    }, [product]);

    useEffect(() => {
        const imageUrl = imageUrls[selectedImageIndex];
        if (imageUrl && imageUrl.length === 24) {
            fetch(`${gBASE_URL}/product-img/${imageUrl}`)
                .then(response => response.blob())
                .then(blob => {
                    const url = URL.createObjectURL(blob);
                    setCurrentImageUrl(url);
                })
                .catch(error => console.error(error));
        } else {
            setCurrentImageUrl(imageUrl);
        }
    }, [selectedImageIndex, imageUrls]);


    const handleInputChange = (event) => {
        setUpdatedProduct({
            ...updatedProduct,
            [event.target.name]: event.target.value,
        });
    };

    const handleAdditionalInformationChange = (value) => {
        setAdditionalInformation(value);
        setUpdatedProduct(prevState => ({
            ...prevState,
            additionalInformation: value
        }));
    };

    const handleDescriptionChange = (value) => {
        setDescription(value);
        setUpdatedProduct(prevState => ({
            ...prevState,
            description: value
        }));
    };

    const handleUpdateProduct = () => {
        // Kiểm tra xem có trường nào bị bỏ trống không
        const emptyFields = Object.values(updatedProduct).some(field => field === '');
        if (emptyFields) {
            toast.error('Please fill out all fields.');
            return;
        }

        // Kiểm tra xem có sự thay đổi nào không
        const noChanges = Object.keys(updatedProduct).every(key => updatedProduct[key] === product[key]) && !isEdited;
        if (noChanges) {
            toast.error('No changes were made.', { autoClose: 3000, theme: "dark" });
            return;
        }

        const productData = {
            ...updatedProduct,
            imageUrls
        };

        // Gửi dữ liệu updatedProduct đến API
        axios.put(`${gBASE_URL}/product/${productid}`, productData, {
            headers: {
                'x-access-token': `${accessToken}`
            }
        })
            .then(response => {
                if (response.status === 200) {
                    toast.success('Product updated successfully!', { autoClose: 3000, theme: "dark" });
                    setUpdatedProduct({});
                    fetchProduct();
                    setIsEdited(false);
                } else {
                    toast.error('Something went wrong!', { autoClose: 3000, theme: "dark" });
                }
            })
            .catch(error => {
                // toast.error(`Error: ${error}`);
            });
    };

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append('image', file);

        if (file.size > 5 * 1024 * 1024) {
            toast.warning('The file size should not exceed 5MB.', { autoClose: 3000, theme: "dark" });
            return;
        }

        try {
            const response = await fetch(`${gBASE_URL}/upload-product-img`, {
                method: 'POST',
                body: formData
            });
            const data = await response.json();

            if (response.ok) {
                const newImageUrls = [...imageUrls];
                newImageUrls.push(data.data);
                setImageUrls(newImageUrls);
                setIsEdited(true);
                setSelectedImageIndex(newImageUrls.length - 1);
            } else {
                // handle error
            }
        } catch (error) {
            // handle error
        }
    };

    const handleImageUrlChange = (event) => {
        const newImageUrls = [...imageUrls];
        newImageUrls.push(event.target.value); // Thêm đường dẫn mới vào cuối mảng
        setImageUrls(newImageUrls);
        setIsEdited(true);
        setSelectedImageIndex(newImageUrls.length - 1); // Chọn hình ảnh mới nhất
    };

    const handleDeleteImage = async () => {
        const imageUrl = imageUrls[selectedImageIndex];
        const newImageUrls = [...imageUrls];
        newImageUrls.splice(selectedImageIndex, 1);
        setImageUrls(newImageUrls);
        setIsEdited(true);

        // Nếu URL hình ảnh là một ID hình ảnh, gọi API để xóa hình ảnh
        if (imageUrl.length === 24) {
            try {
                const response = await fetch(`${gBASE_URL}/product-img/${imageUrl}`, {
                    method: 'DELETE'
                });

                if (!response.ok) {
                    throw new Error('Failed to delete image');
                }
            } catch (error) {
                throw new Error('Failed to delete image');
            }
        }
    };

    console.log(imageUrls)

    const cssString = `
    .ql-toolbar .ql-stroke {
    stroke: ${colors.grey[100]};
    }

    .ql-toolbar .ql-fill,
    .ql-toolbar .ql-stroke.ql-fill {
    fill: ${colors.grey[100]};
    }
    .ql-snow .ql-picker-label {
    color: ${colors.grey[100]};
    }

    .ql-snow .ql-picker-item {
        color: ${colors.grey[100]};
    }
    .ql-toolbar {
    position: sticky;
    top: 0;
    z-index: 1000;
    background: ${colors.blueAccent[700]};
    }
    `
    return (
        <Box m="0 20px">
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="Edit Product" subtitle="Editing Products" />
            </Box>
            <Box style={{ overflow: 'auto', maxHeight: '72vh' }}>
                <Box
                    display="grid"
                    gap="30px"
                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                    sx={{
                        "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                        '& label.Mui-focused': {
                            color: colors.grey[100],
                        },
                        '& .MuiInput-underline:after': {
                            borderBottomColor: colors.grey[100],
                        },
                    }}
                >
                    <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Product Name"
                        name="name"
                        sx={{ gridColumn: "span 4" }}
                        value={updatedProduct.name ?? product?.name ?? ''}
                        onChange={handleInputChange}
                    />
                    <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Description Summary"
                        name="descriptionSummary"
                        sx={{ gridColumn: "span 4" }}
                        value={updatedProduct.descriptionSummary ?? product?.descriptionSummary ?? ''}
                        onChange={handleInputChange}
                    />
                    <Autocomplete
                        fullWidth
                        sx={{ gridColumn: "span 4" }}
                        multiple
                        id="categories-select"
                        options={category.map((item) => item.name)}
                        getOptionLabel={(option) => option}
                        disableCloseOnSelect
                        value={selectedCategories}
                        onChange={(event, newValue) => {
                            setSelectedCategories(newValue);
                            setUpdatedProduct(prevState => ({
                                ...prevState,
                                category: newValue
                            }));
                        }}

                        renderOption={(props, option, { selected }) => (
                            <MenuItem
                                key={option._id}
                                value={option}
                                sx={{ justifyContent: "space-between" }}
                                {...props}
                            >
                                {option}
                                {selected ? <CheckIcon color="info" /> : null}
                            </MenuItem>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="filled"
                                label="Category Select"
                                placeholder="Categories"
                            />
                        )}
                    />
                    <Grid container sx={{ gridColumn: "span 2" }} spacing={1} alignItems="flex-end">
                        <Grid item xs={2}>
                            <FormControl fullWidth>
                                <Select
                                    id="image-select"
                                    fullWidth
                                    value={selectedImageIndex}
                                    onChange={(event) => setSelectedImageIndex(event.target.value)}
                                >
                                    <MenuItem className="new-image" sx={{ paddingLeft: '0px' }}
                                        onClick={() => {
                                            setShowAddImageTooltip(true);
                                            setSelectedImageIndex(imageUrls.length);
                                        }}>
                                        <IconButton>
                                            <AddIcon />
                                        </IconButton>
                                    </MenuItem>
                                    {imageUrls.map((url, index) => (
                                        <MenuItem key={index} value={index} onClick={() => { setShowAddImageTooltip(false) }}>
                                            {`Image ${index + 1}`}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={10}>
                            <TextField
                                required
                                id="imageUrl"
                                name="imageUrl"
                                label="Image Url"
                                type="text"
                                fullWidth
                                variant="filled"
                                value={imageUrls[selectedImageIndex] || ''}
                                onChange={handleImageUrlChange}
                                InputProps={{
                                    endAdornment:
                                        <div style={{ display: 'flex' }}>
                                            {showAddImageTooltip && (
                                                <Tooltip title="Add image">
                                                    <IconButton edge="end" component="label">
                                                        <input type="file" hidden accept='image/*' onChange={handleFileUpload} />
                                                        <AddIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                            <Tooltip title={
                                                <img
                                                    alt="preview"
                                                    src={currentImageUrl}
                                                    style={{ width: '200px', height: '200px' }}
                                                />
                                            } arrow>
                                                <IconButton edge="end">
                                                    <VisibilityIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Delete image" onClick={handleDeleteImage}>
                                                <IconButton edge="end">
                                                    <CloseIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                }}
                            />
                        </Grid>
                    </Grid>
                    <TextField
                        fullWidth
                        sx={{ gridColumn: "span 2" }}
                        required
                        id="buyPrice"
                        name="buyPrice"
                        label="Buy Price ($)"
                        type="number"
                        variant="filled"
                        value={updatedProduct.buyPrice ?? product?.buyPrice ?? ''}
                        onChange={handleInputChange}
                    />
                    <TextField
                        fullWidth
                        sx={{ gridColumn: "span 2" }}
                        required
                        id="promotionPrice"
                        name="promotionPrice"
                        label="Promotion Price ($)"
                        type="number"
                        variant="filled"
                        value={updatedProduct.promotionPrice ?? product?.promotionPrice ?? ''}
                        onChange={handleInputChange}
                    />
                    <TextField
                        fullWidth
                        sx={{ gridColumn: "span 2" }}
                        required
                        id="amount"
                        name="amount"
                        label="Amount"
                        type="number"
                        variant="filled"
                        value={updatedProduct.amount ?? product?.amount ?? ''}
                        onChange={handleInputChange}
                    />
                </Box>
                <style>{cssString}</style>
                <Box>
                    <h2 style={{ marginTop: '15px', fontWeight: 'bold', fontFamily: 'Poppins' }}>Additional Information</h2>
                    <ReactQuill style={{ maxHeight: '450px', overflow: 'auto' }} theme="snow"
                        value={additionalInformation || product?.additionalInformation}
                        onChange={handleAdditionalInformationChange}
                        modules={{
                            toolbar: true,
                            imageResize: {
                                parchment: Quill.import('parchment'),
                                modules: ['Resize', 'DisplaySize']
                            }
                        }} />
                </Box>
                <Box>
                    <h2 style={{ marginTop: '15px', fontWeight: 'bold', fontFamily: 'Poppins' }}>Description</h2>
                    <ReactQuill style={{ maxHeight: '450px', overflow: 'auto' }} theme="snow"
                        value={description || product?.description}
                        onChange={handleDescriptionChange}
                        modules={{
                            toolbar: true,
                            imageResize: {
                                parchment: Quill.import('parchment'),
                                modules: ['Resize', 'DisplaySize']
                            }
                        }} />
                </Box>
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
                <Button onClick={handleUpdateProduct} type="button" color="secondary" variant="contained">
                    Update Product
                </Button>
            </Box>
            <ToastContainer />
        </Box>
    )
}

export default EditProduct;
