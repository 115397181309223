import React, { useState, useEffect } from "react";
import ReactQuill, { Quill } from 'react-quill';
import ImageResize from 'quill-image-resize-module-react';
import 'react-quill/dist/quill.snow.css';
import {
    MenuItem,
    Select,
    FormControl,
    Autocomplete,
    TextField,
    Box,
    useTheme,
    Button,
    Grid,
    IconButton,
    Tooltip
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CheckIcon from "@mui/icons-material/Check";
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import Cookies from "js-cookie";

import { tokens } from "../../theme";
import Header from "../../components/Header";


const CreateProduct = () => {
    const isNonMobile = useMediaQuery("(min-width:600px)");

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [name, setName] = useState('');
    const [additionalInformation, setAdditionalInformation] = useState('');
    const [descriptionSummary, setDescriptionSummary] = useState('');
    const [description, setDescription] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [categories, setCategories] = useState([]);
    const [buyPrice, setBuyPrice] = useState(0);
    const [promotionPrice, setPromotionPrice] = useState(0);
    const [amount, setAmount] = useState(0);

    const [showAddImageTooltip, setShowAddImageTooltip] = useState(false);

    const [image, setImage] = useState(null);

    const [uploadError, setUploadError] = useState('');

    const [category, setCategory] = useState([])

    const gBASE_URL = process.env.REACT_APP_API_URL;

    const accessToken = Cookies.get('accessToken');

    // load category
    const fetchCategory = async () => {
        try {
            const response = await fetch(`${gBASE_URL}/category`);
            const data = await response.json();
            if (response.ok) {
                setCategory(data.data);
            } else {
                // xử lý lỗi
            }
        } catch (error) {
            // xử lý lỗi
        }
    }
    useEffect(() => {
        fetchCategory()
    }, [])

    // load/show image 
    useEffect(() => {
        if (imageUrl && imageUrl.length === 24) { // Check if imageUrl is an ID
            fetch(`${gBASE_URL}/product-img/${imageUrl}`)
                .then(response => response.blob())
                .then(blob => {
                    const url = URL.createObjectURL(blob);
                    setImage(url);
                })
                .catch(error => console.error(error));
        } else {
            setImage(imageUrl);
        }
    }, [imageUrl]);

    // upload image to server
    const handleImageUpload = async (event) => {
        const file = event.target.files[0];
        // Check if the file size is more than 5MB
        if (file.size > 5 * 1024 * 1024) {
            setUploadError('The file size should not exceed 5MB.');
            return;
        }
        const formData = new FormData();
        formData.append('image', file);
        try {
            const response = await fetch(`${gBASE_URL}/upload-product-img`, {
                method: 'POST',
                body: formData
            });
            const data = await response.json();
            if (response.ok) {
                setImageUrl(data.data);
                setUploadError(''); // Clear the error message if the upload is successful
            } else if (response.status === 400) {
                setUploadError('An image with the same name and dimensions already exists!');
            } else {
                // handle other errors
            }
        } catch (error) {
            // handle error
        }
    };

    // create product function
    const handleCreate = async () => {
        // Validate the input
        if (!name || !additionalInformation || !descriptionSummary || !description || !imageUrl || !buyPrice || !promotionPrice || !amount || categories.length === 0) {
            toast.error('All fields are required.', { draggable: true, autoClose: 3000, theme: "dark" });
            return;
        }
        if (buyPrice <= 0 || promotionPrice <= 0) {
            toast.error('Buy Price and Promotion Price cannot be negative or zero.', { draggable: true, autoClose: 3000, theme: "dark" });
            return;
        }
        if (promotionPrice > buyPrice) {
            toast.error('Promotion Price cannot be greater than Buy Price.', { draggable: true, autoClose: 3000, theme: "dark" });
            return;
        }

        const product = {
            name,
            additionalInformation,
            description,
            descriptionSummary,
            category: categories,
            imageUrls: [imageUrl],
            buyPrice,
            promotionPrice,
            amount
        };
        try {
            const response = await fetch(`${gBASE_URL}/product`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': accessToken
                },
                method: 'POST',
                body: JSON.stringify(product)
            });
            if (response.ok) {
                toast.success('Product created successfully', { draggable: true, autoClose: 3000, theme: "dark" });
            } else {
                toast.error('Failed to create product', { draggable: true, autoClose: 3000, theme: "dark" });
            }
        } catch (error) {
            toast.error('Failed to create product', { draggable: true, autoClose: 3000, theme: "dark" });
        }
    };

    // delete image
    const handleDeleteImageUrl = () => {
        // If the imageUrl is an ID, delete the image from the database
        if (imageUrl.length === 24) {
            fetch(`${gBASE_URL}/product-img/${imageUrl}`, {
                method: 'DELETE'
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Failed to delete image');
                    }
                    // If the DELETE request is successful, clear the imageUrl state
                    setImageUrl('');
                })
                .catch(error => console.error(error));
        }
    };

    Quill.register('modules/imageResize', ImageResize);

    const cssString = `
    .ql-toolbar .ql-stroke {
    stroke: ${colors.grey[100]};
    }

    .ql-toolbar .ql-fill,
    .ql-toolbar .ql-stroke.ql-fill {
    fill: ${colors.grey[100]};
    }
    .ql-snow .ql-picker-label {
    color: ${colors.grey[100]};
    }

    .ql-snow .ql-picker-item {
        color: ${colors.grey[100]};
    }
    .ql-toolbar {
    position: sticky;
    top: 0;
    z-index: 1000;
    background: ${colors.blueAccent[700]};
    }
    `
    return (
        <Box m="0 20px">
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="Create" subtitle="Create Products" />
            </Box>
            <Box style={{ overflow: 'auto', maxHeight: '72vh' }}>
                <Box
                    display="grid"
                    gap="30px"
                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                    sx={{
                        "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                        '& label.Mui-focused': {
                            color: colors.grey[100],
                        },
                        '& .MuiInput-underline:after': {
                            borderBottomColor: colors.grey[100],
                        },
                    }}
                >
                    <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Product Name"
                        name="productName"
                        sx={{ gridColumn: "span 4" }}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Description Summary"
                        name="descriptionSummary"
                        sx={{ gridColumn: "span 4" }}
                        value={descriptionSummary}
                        onChange={(e) => setDescriptionSummary(e.target.value)}
                    />
                    <Autocomplete
                        fullWidth
                        sx={{ gridColumn: "span 4" }}
                        multiple
                        id="categories-select"
                        options={category.map((item) => item.name)}
                        getOptionLabel={(option) => option}
                        disableCloseOnSelect
                        value={categories}
                        onChange={(event, newValue) => {
                            setCategories(newValue);
                        }}
                        renderOption={(props, option, { selected }) => (
                            <MenuItem
                                key={option._id}
                                value={option}
                                sx={{ justifyContent: "space-between" }}
                                {...props}
                            >
                                {option}
                                {selected ? <CheckIcon color="info" /> : null}
                            </MenuItem>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="filled"
                                label="Category Select"
                                placeholder="Categories"
                            />
                        )}
                    />
                    <Grid container sx={{ gridColumn: "span 2" }} spacing={1} alignItems="flex-end">
                        <Grid item xs={2}>
                            <FormControl fullWidth>
                                <Select
                                    id="image-select"
                                    fullWidth
                                >
                                    <MenuItem sx={{ paddingLeft: '0px' }} onClick={() => { setShowAddImageTooltip(true) }}>
                                        <IconButton>
                                            <AddIcon />
                                        </IconButton>
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={10}>
                            <TextField
                                required
                                id="imageUrl"
                                name="imageUrl"
                                label="Image Url"
                                type="text"
                                fullWidth
                                variant="filled"
                                value={imageUrl}
                                onChange={(e) => setImageUrl(e.target.value)}
                                helperText={uploadError}
                                error={!!uploadError}
                                InputProps={{
                                    endAdornment:
                                        <div style={{ display: 'flex' }}>
                                            {showAddImageTooltip && (
                                                <Tooltip title="Add image">
                                                    <IconButton edge="end" component="label">
                                                        <input type="file" hidden accept='image/*' onChange={handleImageUpload} />
                                                        <AddIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                            <Tooltip title={
                                                <img
                                                    src={image}
                                                    alt="preview"
                                                    style={{ width: '200px', height: '200px' }}
                                                />
                                            } arrow>
                                                <IconButton edge="end">
                                                    <VisibilityIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Delete image">
                                                <IconButton edge="end" onClick={handleDeleteImageUrl}>
                                                    <CloseIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                }}
                            />
                        </Grid>
                    </Grid>
                    <TextField
                        fullWidth
                        sx={{ gridColumn: "span 2" }}
                        required
                        id="buyPrice"
                        name="buyPrice"
                        label="Buy Price ($)"
                        type="number"
                        variant="filled"
                        value={buyPrice}
                        onChange={(e) => setBuyPrice(e.target.value)}
                    />
                    <TextField
                        fullWidth
                        sx={{ gridColumn: "span 2" }}
                        required
                        id="promotionPrice"
                        name="promotionPrice"
                        label="Promotion Price ($)"
                        type="number"
                        variant="filled"
                        value={promotionPrice}
                        onChange={(e) => setPromotionPrice(e.target.value)}
                    />
                    <TextField
                        fullWidth
                        sx={{ gridColumn: "span 2" }}
                        required
                        id="amount"
                        name="amount"
                        label="Amount"
                        type="number"
                        variant="filled"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                    />
                </Box>
                <style>{cssString}</style>
                <Box
                    m="30px 0 0 0"
                >
                    <h2 style={{ marginTop: '15px', fontWeight: 'bold', fontFamily: 'Poppins' }}>Additional Information</h2>
                    <ReactQuill style={{ maxHeight: '450px', overflow: 'auto' }} value={additionalInformation} onChange={content => setAdditionalInformation(content)} theme="snow"
                        modules={{
                            toolbar: true,
                            imageResize: {
                                parchment: Quill.import('parchment'),
                                modules: ['Resize', 'DisplaySize']
                            }
                        }} />
                </Box>
                <Box
                    m="30px 0 0 0"
                >
                    <h2 style={{ marginTop: '15px', fontWeight: 'bold', fontFamily: 'Poppins' }}>Description</h2>
                    <ReactQuill style={{ maxHeight: '450px', overflow: 'auto' }} value={description} onChange={content => setDescription(content)} theme="snow"
                        modules={{
                            toolbar: true,
                            imageResize: {
                                parchment: Quill.import('parchment'),
                                modules: ['Resize', 'DisplaySize']
                            }
                        }} />
                </Box>
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
                <Button type="button" color="secondary" variant="contained" onClick={handleCreate}>
                    Create New Product
                </Button>
            </Box>
            <ToastContainer />
        </Box>
    )
}

export default CreateProduct;
